/* vietnamese */
@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2RXTr6YS-zF4S-kcSWSVi_szLviuEViw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2RXTr6YS-zF4S-kcSWSVi_szLuiuEViw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2RXTr6YS-zF4S-kcSWSVi_szLgiuE.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2RXTr6YS-zF4S-kcSWSVi_szLviuEViw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2RXTr6YS-zF4S-kcSWSVi_szLuiuEViw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/dancingscript/v24/If2RXTr6YS-zF4S-kcSWSVi_szLgiuE.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.section-countdown {
    font-family: 'Dancing Script', Helvetica, Arial, sans-serif;
    font-size: 1.6em;
    background-repeat: no-repeat;
    background-size: cover;

    audio{
        display: none;
    }
    
    &__wrapper {
        max-width: 1100px;
        margin: 0 auto;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1 {
        color: #ffffff;
        text-align: center;
        font-size: 4rem;
    }

    .timer {
        display: flex;
        align-items: center;
    }

    .clock {
        overflow: hidden;
        background-color: #fff;
        color: #222;
        border-radius: 30%;
        position: relative;
        width: 20%;
        height: 0;
        padding-bottom: 20%;
        margin: 3% 0 3% 4%;
        text-align: center;

        .number {
            font-size: 350%;
            font-weight: 700;
            height: 0;
            line-height: 190%;
        }

        .text {
            font-size: 130%;
            text-transform: uppercase;
            background-color: #eee;
            position: absolute;
            bottom: 0;
            width: 100%;
            line-height: 180%;
            left: 0;
            text-align: center;
        }
    }
}

@media (max-width:767px) {
    .section-countdown {
        h1{
            font-size: 2.5rem;
        }
        .clock {
            .number {
                font-size: 2rem;
                font-weight: 700;
                height: 0;
                line-height: 190%;
            }
    
            .text {
                font-size: 1rem;
            }
        }
    }
}