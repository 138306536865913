.weather {
    // position: relative;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__wrapper {
        width: auto;
        max-width: 500px;
        padding: 2rem 2.5rem;
        border-radius: 2.5rem;
        background-color: #1b1c48;
        color: #fff;
        width: calc(100% / 3);
        margin: 0.75rem;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .weather-title {
                font-size: 1.7rem;
                margin: 10px 0;
            }
        }

        &__middle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .weather-temp-main {
                font-weight: bold;
                font-size: 4rem;
                .weather-tepm {
                    vertical-align: text-top;
                    font-size: 2.7rem;
                    color: #f8c514;
                    font-weight: bold;
                }
            }
        }

        &__bottom {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
                font-size: 1.5rem;
                color: #f8c514;
            }
        }
    }

    .paragraph {
        font-size: 1.2rem;
        color: #cccbd9;
        margin-bottom: 0;
    }

}

@media (max-width:767px) {
    .weather{
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        &__wrapper{
            width: 100%;
        }
    }
}