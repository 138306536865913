.header {
  position: relative;
  z-index: 3;
  min-height: 50px;
  background: #FFFFFF;
  margin: 0;
  padding: 0;
  border: unset;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  display: flex;
  align-items: center;

  &__button {
    width: 50px;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}