.calculate{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    &-age{
        background-color: #fff;
        padding: 30px;
        box-shadow: 0 8px 32px rgb(2 4 24);
        max-width: 680px;
        width: 100%;
        .form-group{
            position: relative;
            .btn-age{
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}