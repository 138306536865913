.sidebar {
    width: 50px;
    height: 100%;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background: #F2f2f2;
    // box-shadow: 0 8px 32px rgb(2, 4, 24);
    // border-right: 2px solid rgba(255, 255, 255, 0.09);
    transition: 0.4s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    &__logo {
        width: 100%;
        min-height: 140px;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-direction: column;

        img {
            width: 30px;
            max-width: 100%;
            transition: 0.4s;
        }

        .name {
            color: #242424;
            font-size: 2rem;
            margin-top: 12px;
            font-variant: small-caps;
            pointer-events: none;
            scale: 0;
            opacity: 0;
        }
    }

    &__nav {
        padding: 0;
    }

    .nav-title {
        color: #242424;
        font-weight: bold;
        margin: 2rem 0 1rem;
        pointer-events: none;
        opacity: 0;
    }

    .nav-menu {
        padding: 0;
        margin: 0;
        max-height: 300px;
        overflow-y: auto;
        
        &__item {
            list-style: none;

            a {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 4px;
                width: 100%;
                padding: 0.75rem 1rem;
                color: #242424;
                transition: .3s;
                &:hover{
                    text-decoration:none
                }
                svg {
                    font-size: 1rem;
                    width: 1rem;
                    height: 1rem;
                }

                span {
                    font-size: 0;
                    margin-left: 8px;
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        &__item.active a{
            color:#fff;
            background: #007bff !important;
        }

        &__item:hover {
            background: rgba(255, 255, 255, .1);
        }
    }

    hr {
        width: 100%;
        height: 2px;
        border-radius: 3px;
        margin: 40px 0 50px;
        background: rgba(255, 255, 255, .1);
        opacity: 0;
    }

    &.open {
        width: 280px;
        padding: 0 1rem;

        .sidebar__logo {
            img {
                width: 96px;
            }

            .name {
                scale: 1;
                opacity: 1;
                transition: 0.4s;
                transition-delay: 0.2s;
            }
        }

        .nav-title {
            opacity: 1;
            transition: .4s;
            transition-delay: .2s;
        }

        .nav-menu {
            &__item {
                a {
                    svg {}

                    span {
                        font-size: 1rem;
                        opacity: 1;
                        pointer-events: visible;
                        transition: .4s;
                        transition-delay: .2s;
                    }
                }
            }

        }

        hr {
            opacity: 1;
            transition: .4s;
        }
    }

}
@media (max-width:767px) {
    .sidebar{
        width: 0;
    }
    #content{
        margin-left: 0;
    }
}