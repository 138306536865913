.bank {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    &-list {
        background-color: #fff;
        padding: 30px;
        box-shadow: 0 8px 32px rgb(2 4 24);
        max-width: 680px;
        width: 100%;
        max-height: 650px;
        overflow-y: auto;

        .item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            

            &-image {
                width: 180px;

                img {
                    max-width: 100%;
                }
            }

            &-info {
                width: calc(100% - 260px);
                padding-left: 2rem;

                ul {
                    padding: 0;
                    margin: 0;
                }
            }

            &-action {
                width: 80px;
                button{
                    margin-bottom: 0.2rem;
                }
            }
        }
        .item + .item {
            padding-top: 1rem;
            margin-top: 1rem;
            border-top: 1px dashed #ddd;
        }
    }
}
@media (max-width:767px) {
    .bank{
        &-list{
            .item {
                flex-direction: column;
                &-image,
                &-info,
                &-action {
                    width: 100%;
    
                }
                &-info{
                    margin: 1.2rem 0;
                }
                &-action {
                    button + button{
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}