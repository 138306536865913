.genarate{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    &-pass{
        width: 400px;
        background-color: #000000;
        box-shadow: 0 4px 10px rgb(0 0 0 / 50%);
        margin: auto;
        padding: 10px;
        &__header{
            margin-bottom: 1rem;
            &__result{
                background-color: #ffffff;
                display: flex;
                align-items: center;
                height: 70px;
                width: 100%;
                position: relative;
                padding: 1rem;
                overflow: auto;
                #copy{
                    background-color: white;
                    border: none;
                    color: rgb(29, 2, 56);
                    cursor: pointer;
                    font-family: inherit;
                    font-weight: bold;
                    padding: 0.25rem;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(0, 20%);
                    transition: opacity 0.2s ease, transform 0.2s ease;
                    opacity: 0;
                }
                &:hover{
                    #copy{
                        opacity: 1;
                        transform: translate(0, 0%);
                    }
                }
            }
        }
        &__body{
    
            .form-control{
                margin-bottom: 1rem;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 0;   
                label{
                    color: #fff;
                    margin: 0;
                    cursor: pointer;
                }
                input{
                    &:focus{
                        outline: 0;
                        box-shadow: 0 0 0 0.25rem rgb(32 107 196 / 25%)
                    }
                }
            }
            .btn{
                border-radius: 0;
            }
        }
    }
}